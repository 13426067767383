'use client';
import { BaseComp } from 'components';
import { API } from 'data';
import { useEffect, useState } from 'react';
import { RestAPI } from 'scripts';
import Error404Comp from 'tehranto/404/404.index';
import { LanguageEnum } from 'types';
import { Spin } from 'antd';

export default function NotFound() {
    const [lang, setLang] = useState<LanguageEnum>();
    useEffect(() => {
        RestAPI.get<{ lang: LanguageEnum }>(API.middlewares.lang)
            .then((res) => setLang(res.data.lang))
            .catch(() => setLang(LanguageEnum.fa));
    });

    if (lang !== undefined)
        return (
            <BaseComp lang={lang} profile={undefined}>
                <Error404Comp />
            </BaseComp>
        );
    return (
        <div className={`w-100 center-content min-h-100-vh`}>
            <Spin className="h-100 center-content d-flex-i" size="large" />
        </div>
    );
}
